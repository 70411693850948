* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Dosis', sans-serif;
  letter-spacing: 1.5px;
  word-spacing: 2px;
  font-weight: 700;
  user-select: none;
}


a {
  text-decoration: none !important;
}

body {
  background: url(./images/back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

:root {
  --mainColor: #796542;
  --textColor: #000;
  --transparent: transparent;
}

.bg-center {
  background: radial-gradient(#ad8c5457, #fff);
}




.main-color {
  color: var(--mainColor) !important;
}

.header-section .active {
  color: var(--mainColor) !important;
}

.header-section a {
  color: #fff;
}

.sec-home-1 {
  background: linear-gradient(rgba(3, 3, 3, 0.9), rgba(99, 98, 98, 0.3)), url(./images/Bg.jpg);
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.sec-cont-1 {
  background: linear-gradient(rgba(3, 3, 3, 0.9), rgba(99, 98, 98, 0.3)), url(./images/bbgg.jpg);
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.is-sticky {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding: 0;
  background-color: #000;
}

.jt {
  text-align: justify;
}

.btn.call {
  background-color: #ad8c54;
  color: #000;
  font-weight: 700;
  transition: all ease-in-out 0.5s;
}

.btn.call:hover {
  background-color: #000;
  color: #fff;
  transition: all ease-in-out 0.5s;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev {
  background: var(--mainColor) !important;
  padding: 25px !important;
  left: -15px !important;
  border-radius: 50% !important;
}

.swiper-button-prev::after {
  font-size: 25px !important;
  color: #fff !important;
}

.swiper-button-next {
  background: var(--mainColor) !important;
  padding: 25px !important;
  right: -15px !important;
  border-radius: 50% !important;
}

.swiper-button-next::after {
  font-size: 25px !important;
  color: #fff !important;
}

footer.foot {
  background-image: url(./images/Footer.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  background-position: center;
}








@media only screen and (max-width: 1024px) {
  .sec-home-2 .container {
    width: 90% !important;
  }

  .header-section .navbar-nav {
    background-color: #000 !important;
    padding: 15px 0;
    border-radius: 10%;
  }

  .header-section .navbar-toggler-icon {
    background-image: url(./images/menu.png) !important;
  }
}

@media only screen and (max-width: 600px) {}